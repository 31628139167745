import Vue from 'vue'
import Router from 'vue-router'
import {checkTokenBeforeRouteEnter} from '@/utils/authoVerify'

Vue.use(Router)

/* Layout */
import Layout from '@/layout'

// 公共路由
export const constantRoutes = [
  {
    path: '/',
    component: Layout,
    redirect: '/index',
    children: [
      {
        path: '/index',
        component: () => import('@/views/index'),
        name: 'Index',
        meta: { title: '首页', icon: 'dashboard', noAuth: true}
      },
      {
        path: '/StarVideoOrder',
        component: () => import('@/views/StarVideoOrder'),
        name: 'StarVideoOrder',
        meta: { title: '订单投后数据', icon: 'dashboard'}
      },
      {
        path: '/StarVideoDailyData',
        component: () => import('@/views/StarVideoDailyData'),
        name: 'StarVideoDailyData',
        meta: { title: '订单分天数据', icon: 'dashboard'}
      },
      {
        path: '/StarVideoFlowPush',
        component: () => import('@/views/StarVideoFlowPush'),
        name: 'StarVideoFlowPush',
        meta: { title: '订单-流量助推数据', icon: 'dashboard'}
      },
      {
        path: '/StarVideoRealTime',
        component: () => import('@/views/StarVideoRealTime'),
        name: 'StarVideoRealTime',
        meta: { title: '订单-助推分时导出', icon: 'dashboard'}
      },
      {
        path: '/StarTaskVideoOrderData',
        component: () => import('@/views/StarTask/Video/OrderData'),
        name: 'StarTaskVideoOrderData',
        meta: { title: '视频订单投后数据', icon: 'dashboard'}
      },
      {
        path: '/StarTaskVideoDailyData',
        component: () => import('@/views/StarTask/Video/DailyData'),
        name: 'StarTaskVideoDailyData',
        meta: { title: '视频订单分天数据', icon: 'dashboard'}
      },
      {
        path: '/StarTaskVideoIntegration',
        component: () => import('@/views/StarTask/Video/Integration'),
        name: 'StarTaskVideoIntegration',
        meta: { title: '视频订单一体化导出', icon: 'dashboard'}
      },
      {
        path: '/StarTaskVideoIntegrationRealTime',
        component: () => import('@/views/StarTask/Video/IntegrationRealTime'),
        name: 'StarTaskVideoIntegrationRealTime',
        meta: { title: '助推分时导出', icon: 'dashboard'}
      },
      {
        path: '/FlowPushData',
        component: () => import('@/views/FlowPushData'),
        name: 'FlowPushData',
        meta: { title: '助推投后数据', icon: 'dashboard'}
      },
      {
        path: '/FlowPushRealTime',
        component: () => import('@/views/FlowPushRealTime'),
        name: 'FlowPushRealTime',
        meta: { title: '助推实时数据', icon: 'dashboard'}
      },
      {
        path: '/FlowPushDataCreativeInfo',
        component: () => import('@/views/FlowPushDataCreativeInfo'),
        name: 'FlowPushDataCreativeInfo',
        meta: { title: '助推创意信息', icon: 'dashboard'}
      },
      {
        path: '/Mission',
        component: () => import('@/views/Mission'),
        name: 'Mission',
        meta: { title: '任务管理', icon: 'dashboard'}
      },
      {
        path: '/Scheduler',
        component: () => import('@/views/Scheduler'),
        name: 'Scheduler',
        meta: { title: '定时任务', icon: 'dashboard'}
      },
      {
        path: '/Test1',
        component: () => import('@/views/Test/Test1'),
        name: 'Test1',
        meta: { title: '测试1', icon: 'dashboard'}
      }
    ]
  }
]



// 防止连续点击多次路由报错
const routerPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(err => err)
}

const router = new Router({
  base: process.env.VUE_APP_CONTEXT_PATH,
  mode: 'history', // 去掉url中的#
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
});

// 全局前置守卫
router.beforeEach((to, from, next) => {
  // 如果路由配置了 noAuth: true，则直接跳过权限校验
  if (to.meta.noAuth) {
    next();
  } else {
    checkTokenBeforeRouteEnter(to, from, next);
  }
});

export default router;
